import React, {Component, useState, useRef} from 'react'
import {
  Box,
  Button,
  Flex,
  Heading,
  Stack,
  HStack,
  FormControl,
  FormLabel,
  Input,
  Editable,
  EditablePreview,
  EditableInput,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  
} from '@chakra-ui/react';

import Navigation from '../Navigation/Navigation';

class Account extends Component {
    render () {
      return (
        <>
        <Navigation
            bg = "#F3F2EF"
            state = {this.props.state}
            onRouteChange = {this.props.onRouteChange}
            
            />
        <Flex
          minH={'94.6vh'}
          align={'center'}
          justify={'center'}
          // bg={useColorModeValue('gray.50', 'gray.800')}
          bg="#F3F2EF"
          >
          <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
            <Stack align={'center'}>
            <HStack>
            <Heading fontStyle = "oblique" as="h0" fontSize="3em" m="10px" mr="-1" color = "gray.500">Account</Heading>
            
            </HStack>
            </Stack>
            <Box w = '400px'
              rounded={'lg'}
              // bg={useColorModeValue('white', 'gray.700')}
              // boxShadow={'lg'}
              p={8}
              // bg="#F3F2EF"
              >
              
              <Stack spacing={4}>
                {this.props.state.accountDetails.editingPassword === true
                ?
                
                  <PasswordEditor
                  state = {this.props.state}
                  onFormTextChangeUpdateAcctInfo = {this.props.onFormTextChangeUpdateAcctInfo}
                  onClickChangePassword = {this.props.onClickChangePassword}
                  onSaveNewPassword = {this.props.onSaveNewPassword}
                  />
                
                :
                  <>
                  <FormControl id="username">
                    <FormLabel>Username</FormLabel>
                    <EditableGroup
                    state = {this.props.state}
                    onChangeAccountInfo = {this.props.onChangeAccountInfo}
                    onFormTextChangeUpdateAcctInfo = {this.props.onFormTextChangeUpdateAcctInfo}
                    type = 'username'
                    />
                  </FormControl>
                  <FormControl id="email">
                    <FormLabel>Email</FormLabel>
                    <EditableGroup
                    state = {this.props.state}
                    onChangeAccountInfo = {this.props.onChangeAccountInfo}
                    onFormTextChangeUpdateAcctInfo = {this.props.onFormTextChangeUpdateAcctInfo}
                    type = 'email'
                    />
                    <Stack spacing={10}>
                    <Stack
                      direction={{ base: 'column', sm: 'row' }}
                      align={'start'}
                      justify={'space-between'}>
                    </Stack>
                    <Button
                      bg={'red.400'}
                      color={'white'}
                      _hover={{
                        bg: 'red.500',
                      }}
                      onClick = {() => {this.props.onClickChangePassword(true)}}>
                      Change Password
                    </Button>
                    {this.props.state.accountDetails['subscr_info']['status'] === 'inactive'
                    ?
                      <Button
                        bg={'red.600'}
                        color={'white'}
                        _hover={{
                          bg: 'red.700',
                        }}
                        onClick = {() => {this.props.onRouteChange('payments')}}>
                        Subscribe to WriteWizard Pro
                      </Button>
                    : this.props.state.accountDetails['subscr_info']['status'] === 'cancelled'
                      ?
                        <Button
                          bg={'red.600'}
                          color={'white'}
                          _hover={{
                            bg: 'red.700',
                          }}
                          onClick = {() => {this.props.onChangeSubscription('resubscribe')}}>
                          Resubscribe to WriteWizard Pro
                        </Button>
                    : this.props.state.accountDetails['subscr_info']['status'] === 'active'
                      ? 
                        <UnsubscribeButton
                        onChangeSubscription = {this.props.onChangeSubscription}
                        />
                        
                    : 
                      null
                    }
                   </Stack>
                  </FormControl>
                </>
                }
                
                
              </Stack>
            </Box>
          </Stack>
        </Flex>
        </>
      )
    }
  }


  const UnsubscribeButton = ({ onChangeSubscription }) => {
    const [isOpen, setIsOpen] = useState(false);
    const cancelRef = useRef();
  
    const openDialog = () => setIsOpen(true);
    const closeDialog = () => setIsOpen(false);
  
    const handleUnsubscribe = (type) => {
      onChangeSubscription(type); // Call the actual delete function passed in as a prop
      closeDialog(); // Close the dialog after deletion
    };
  
    return (
      <>
        {/* Trigger the confirmation dialog */}
        
        <Button
        bg={'red.600'}
        color={'white'}
        _hover={{
          bg: 'red.700',
        }}
        onClick = {openDialog}>
        Unsubscribe from WriteWizard Pro
      </Button>
      
  
        {/* Confirmation Dialog */}
        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={closeDialog}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Unsubscribe
              </AlertDialogHeader>
  
              <AlertDialogBody>
                Are you sure you want to unsubscribe? Your subscription will be cancelled at the end of the current period and you will retain access until then.
              </AlertDialogBody>
  
              <AlertDialogFooter>
                {/* Cancel button */}
                <Button ref={cancelRef} onClick={closeDialog}>
                  Cancel
                </Button>
  
                {/* Confirm deletion button */}
                <Button colorScheme="red" onClick = {() => handleUnsubscribe('unsubscribe')} ml={3}>
                  Unsubscribe
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </>
    );
  };

class EditableGroup extends Component {
  render() {
    let accountInfo = this.props.state.accountDetails.account_info
    let placeholderValue = this.props.type == 'username' ? accountInfo.username : accountInfo.email
    return (
      <Editable 
        defaultValue = {placeholderValue}
          onSubmit = {(newValue) => {
            if (newValue !== accountInfo[this.props.type]) {
                this.props.onChangeAccountInfo(this.props.type)
              }
            }
          }
        >
          <EditablePreview
          width="100%"              /* Full width like an input */
          padding="8px"             /* Padding similar to an input */
          pl = '16px'
          border="1px solid"        /* Border like an input */
          borderColor="gray.200"    /* Border color */
          borderRadius="md"         /* Rounded corners */
          backgroundColor="white"   /* Background color */
          _hover={{ borderColor: 'gray.400' }} /* Hover effect */
          _focus={{ borderColor: 'blue.400' }} /* Focus effect */
          />
          <EditableInput 
          padding="8px"
          pl = '16px'
          border="1px solid"
          borderColor="gray.300"
          borderRadius="md"
          backgroundColor="white"
          _hover={{ borderColor: 'gray.400' }}
          _focus={{ borderColor: 'blue.400' }}
          
          onChange = {
            (event) => {
              this.props.onFormTextChangeUpdateAcctInfo('accountDetails', this.props.type, event.target.value)
            }
          }/>
            
        </Editable>
    )
  }
}

class PasswordEditor extends Component {
  render() {
    return (
      <Stack spacing={4}>
      <FormControl id="current_password">
        <FormLabel>Current Password</FormLabel>
        <Input type="password" bg = "white"
        onChange = {(event) => {this.props.onFormTextChangeUpdateAcctInfo('accountDetails', 'current_password', event.target.value)}}/>
      </FormControl>
      <FormControl id="newPassword1">
        <FormLabel>New Password</FormLabel>
        <Input type="password" bg = "white"
        onChange = {(event) => {this.props.onFormTextChangeUpdateAcctInfo('accountDetails', 'new_password_1', event.target.value)}}/>
      </FormControl>
      <FormControl id="newPassword2">
        <FormLabel>Repeat New Password</FormLabel>
        <Input type="password" bg = "white"
        onChange = {(event) => {this.props.onFormTextChangeUpdateAcctInfo('accountDetails', 'new_password_2', event.target.value)}}/>
      </FormControl>
      
      <Stack spacing={10}>
      <Stack
        direction={{ base: 'column', sm: 'row' }}
        align={'start'}
        justify={'space-between'}>
      </Stack>
      <Button
        bg={'red.400'}
        color={'white'}
        _hover={{
          bg: 'red.500',
        }}
        onClick = {() => {this.props.onClickChangePassword(false)}}>
        Cancel
      </Button>
      <Button
        bg={'red.500'}
        color={'white'}
        _hover={{
          bg: 'red.600',
        }}
        onClick = {this.props.onSaveNewPassword}>
        Save New Password
      </Button>
      </Stack>
    </Stack>
    )
  }
}

export default Account;