import React, {Component, useState, useRef} from 'react'
import {Form} from 'react-bootstrap'
import {
    Box,
    Grid,
    GridItem,
    Button,
    Card,
    CardHeader,
    CardBody,
    HStack,
    VStack,
    Text,
    Image,
    Spacer,
    Editable,
    EditablePreview,
    EditableInput,
    Heading,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
   
  } from '@chakra-ui/react';

import Navigation from '../Navigation/Navigation';
import { AddIcon, CloseIcon } from '@chakra-ui/icons';


class BooksIndex extends Component {
    render () {
      return (
        <>
          <Grid templateColumns="repeat(48, 1fr)" bg="#F3F2EF">
          <GridItem
            as="main"
            colSpan="48" 
            bg="#F3F2EF" //Hier veranderen voor bg kleur page.
            >
            <Navigation
            bg = "#F3F2EF"
            state = {this.props.state}
            onRouteChange = {this.props.onRouteChange}
            onLogOut = {this.props.onLogOut}
            />
            <Box minH = "93vh" display="flex"  justifyContent={'center'}>
              <Grid templateColumns="repeat(4, 1fr)"  p ="10px">
              <Books
                books = {this.props.state.books}
                onCreateBook = {this.props.onCreateBook}
                onDeleteBook = {this.props.onDeleteBook}
                onViewBook = {this.props.onViewBook}
                onFormTextChangeBookTitle = {this.props.onFormTextChangeBookTitle}
                onSaveBookTitle = {this.props.onSaveBookTitle}
              />
              </Grid>
            {/* {this.props.state.search.active === true 
            ?
            <Grid templateColumns="repeat(6, 1fr)"  p ="10px">
            <Articles
            state = {this.props.state}
            onClickViewArticle = {this.props.onClickViewArticle}
            />
            </Grid>
            :
            null} */}

            </Box>
          </GridItem>
          </Grid>
        </>
      )
    }
  }

class Books extends Component { 
  render() {
    let books = this.props.books
    return (
      <>
        {books['exists'] === true 
          ? 
          books['items'].map(book => (
              <Book
               id = {book.id} 
               title = {book.title} 
               onViewBook = {this.props.onViewBook} 
               onDeleteBook = {this.props.onDeleteBook}
               onFormTextChangeBookTitle = {this.props.onFormTextChangeBookTitle}
               onSaveBookTitle = {this.props.onSaveBookTitle}
               />
            )
          )
          :
            null
        }
        <CreateBook
        onCreateBook = {this.props.onCreateBook}
        />
      </>
    )
  }
}

class Book extends Component {
  render() {
    return (
    <Box 
    gridColumn="span 1" p = "10px" display="flex" alignItems={'center'} justifyContent={'center'}
    >
      <GridItem> 
        <Card >
        <CardHeader display="flex" justifyContent={'right'}>
          {/* <Spacer/> */}
          <DeleteBookIcon
          id = {this.props.id} 
          onDeleteBook = {this.props.onDeleteBook}/>
          
        </CardHeader>
        <CardBody >
        <Box p="4"
        // borderWidth="1px"
        // borderRadius="lg"
        >
        <VStack>
          <EditableTitle 
          id = {this.props.id} 
          title = {this.props.title}
          onFormTextChangeBookTitle = {this.props.onFormTextChangeBookTitle}
          onSaveBookTitle = {this.props.onSaveBookTitle}
          />
          <Image src="book-icon.jpg" m = "12px"/>
          <CustomButton text = "Continue" onClick = {() => this.props.onViewBook(this.props.id)}/>
          </VStack>
        </Box>
        </CardBody>
        </Card>
        </GridItem>
      </Box>
    )
  }
}

const DeleteBookIcon = ({ onDeleteBook, id }) => {
  const [isOpen, setIsOpen] = useState(false);
  const cancelRef = useRef();

  const openDialog = () => setIsOpen(true);
  const closeDialog = () => setIsOpen(false);

  const handleDelete = (id) => {
    onDeleteBook(id); // Call the actual delete function passed in as a prop
    closeDialog(); // Close the dialog after deletion
  };

  return (
    <>
      {/* Trigger the confirmation dialog */}
      
      <CloseIcon
          cursor='pointer'
          onClick = {openDialog}/> 
    

      {/* Confirmation Dialog */}
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={closeDialog}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Book
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete this book? This action cannot be undone.
            </AlertDialogBody>

            <AlertDialogFooter>
              {/* Cancel button */}
              <Button ref={cancelRef} onClick={closeDialog}>
                Cancel
              </Button>

              {/* Confirm deletion button */}
              <Button colorScheme="red" onClick = {() => {handleDelete(id)}} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};


class EditableTitle extends Component {
  render() {
    return (
      <Editable 
      defaultValue = {this.props.title} 
      onSubmit = {(newTitle) => {
        if (newTitle !== this.props.title) {
          this.props.onSaveBookTitle(this.props.id)
        }
      }
      }
      >
        <EditablePreview
        // fontSize="xl"
        as={Heading} size="lg"
        />
        <EditableInput 
        fontSize="2xl"
        fontStyle="italic"
        bg = "white"
        
        onChange = {
          (event) => {
            this.props.onFormTextChangeBookTitle('books', this.props.id, 'title', event.target.value)
          }
        }/>
        
      </Editable>
    )
  }
}

class CreateBook extends Component {
  render() {
    return (
    <Box 
    gridColumn="span 1" p = "10px" display="flex" alignItems={'center'} justifyContent={'center'}
    >
      <GridItem> 
        <Card >
        <CardBody >
        <Box p="4"
        // borderWidth="1px"
        // borderRadius="lg"
        >
        <VStack>
          {/* <Text fontWeight="bold" fontSize="xl" mb="2">
            New Book
          </Text> */}
          <AddIcon
          cursor='pointer'
          onClick = {this.props.onCreateBook}
           />
          </VStack>
        </Box>
        </CardBody>
        </Card>
        </GridItem>
      </Box>
    )
  }
}

class CustomButton extends Component {
  render() {
    return (
      <Button
        px={6}
        w = {this.props.w}
        fontSize={'lg'}
        bg={'red.400'}
        color={'white'}
        boxShadow={
            '0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)'
        }
        _hover={{
            bg: 'red.500',
        }}
        _focus={{
            bg: 'red.500',
        }}
        onClick = {this.props.onClick}
      >{this.props.text}
      </Button>
    )
  }
}





export default BooksIndex