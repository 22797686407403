// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }`, "",{"version":3,"sources":["webpack://./src/subsystems/Navigation/Navigation.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,oBAAoB;EACtB","sourcesContent":[".navbar {\n    padding-top: 0rem;\n    padding-bottom: 0rem;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
