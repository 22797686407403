import React, {Component, useState, useRef} from 'react'
import {Form} from 'react-bootstrap'
import {
    Box,
    Grid,
    GridItem,
    SimpleGrid,
    FormControl,
    Input,
    HStack,
    VStack,
    Button,
    Card,
    Text,
    Spacer,
    CardBody,
    Heading,
    Divider,
    Editable,
    EditablePreview,
    EditableInput,
    EditableTextarea,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
  } from '@chakra-ui/react';
import Sidebar from '../Sidebar/Sidebar';
import Navigation from '../Navigation/Navigation';
import { CloseIcon, EditIcon, DeleteIcon } from '@chakra-ui/icons';


class ChapterIndex extends Component {
    render () {
      return (
        <>
        <Grid templateColumns="repeat(48, 1fr)" bg="gray.20">
        <GridItem
          as="aside"
          colSpan="7" 
          bg="red.300"
          minHeight={{ lg: '100vh' }}
          pt={{ base: '20px'}}
        >
        <Sidebar
        state = {this.props.state}
        onRouteChange = {this.props.onRouteChange}
        getOverview = {this.props.getOverview}
        onViewChapter = {this.props.onViewChapter}
        onDeleteChapter = {this.props.onDeleteChapter}
        onGeneratePage = {this.props.onGeneratePage}
        onCreateChapter = {this.props.onCreateChapter}
        onDeleteSnippet = {this.props.onDeleteSnippet}
        onSaveChapterTitle = {this.props.onSaveChapterTitle}
        onFormTextChangeUpdate = {this.props.onFormTextChangeUpdate}
        />
        </GridItem>
        <GridItem
        as="main"
        colSpan="41" 
        // bg="gray.30"
        >
        <Navigation
        bg="gray.20"
        state = {this.props.state}
        onRouteChange = {this.props.onRouteChange}
        onLogOut = {this.props.onLogOut}
        />
        <SimpleGrid p ="10px" minChildWidth = "250px">
        <Box  display="flex" alignItems={'center'} justifyContent={'center'}>
        <VStack>
          <Heading
          fontSize="3xl"
          fontStyle="oblique"
          fontWeight="bold"
          textAlign="center"
          color="gray.700"
          >{this.props.state.chapter.chapter_title}</Heading>
          <Snippets
            state = {this.props.state}
            onFormTextChange = {this.props.onFormTextChange}
            generateText = {this.props.generateText}
            onDeleteSnippet = {this.props.onDeleteSnippet}
            onFormTextChangeUpdate = {this.props.onFormTextChangeUpdate}
            onSaveSnippet = {this.props.onSaveSnippet}
            onEditSnippet = {this.props.onEditSnippet}
            onCancelEditSnippet = {this.props.onCancelEditSnippet}
          />
          <Divider/>
          <Text>{this.props.state.chapter.snippets['nr_words']} words</Text>
          <CustomButton
            text = "New Snippet"
            w = '150px'
            onClick = {() => {this.props.onCreateSnippet(this.props.state.chapter.chapter_id)}}
          />
          
        </VStack>
        </Box>
        </SimpleGrid>
        </GridItem>
        </Grid>
        </>
      )
    }
  }

  class Snippets extends Component {
    render() {
      let snippets = this.props.state.chapter.snippets
      return (
        <>
        {snippets['exists'] == true 
          ? 
          snippets['items'].map(snippet => (
            <Snippet
              id = {snippet.id} 
              text = {snippet.text} 
              editing_obj = {snippet.editing_obj}
              state = {this.props.state}
              onDeleteSnippet = {this.props.onDeleteSnippet}
              onFormTextChange = {this.props.onFormTextChange}
              onFormTextChangeUpdate = {this.props.onFormTextChangeUpdate}
              generateText = {this.props.generateText}
              onEditSnippet = {this.props.onEditSnippet}
              onSaveSnippet = {this.props.onSaveSnippet}
              onCancelEditSnippet = {this.props.onCancelEditSnippet}
            />
            )
          )
          :
            null
          }
        </>
      )
    }
  }

  class Snippet extends Component {
    render() {
        let editing_obj = this.props.editing_obj
        return (
          <>
            {editing_obj.editing == true
            ?
              <EditingSnippet
                id = {this.props.id} 
                editing_obj = {this.props.editing_obj}
                state = {this.props.state}
                onDeleteSnippet = {this.props.onDeleteSnippet}
                onFormTextChange = {this.props.onFormTextChange}
                onFormTextChangeUpdate = {this.props.onFormTextChangeUpdate}
                generateText = {this.props.generateText}
                onSaveSnippet = {this.props.onSaveSnippet}
                onCancelEditSnippet = {this.props.onCancelEditSnippet}
              /> 
            :
              <SavedSnippet
                id = {this.props.id} 
                text = {this.props.text} 
                onDeleteSnippet = {this.props.onDeleteSnippet}
                onFormTextChange = {this.props.onFormTextChange}
                onFormTextChangeUpdate = {this.props.onFormTextChangeUpdate}
                generateText = {this.props.generateText}
                onEditSnippet = {this.props.onEditSnippet}
              />
            }
          </>
        )
    }
} 

class EditingSnippet extends Component {
  render() {
    // let displayText = this.props.generator_obj.active == true ? this.props.generator_obj.response.text : this.props.text
    return (
      <>
      {/* <Box w = "1000px"  > */}
      <Card 
      bg = "#E2E1E1"
      w = '40vw' 
      color="gray.40" 
      p = "20px" 
      display="flex" 
      borderRadius="lg"
      alignItems={'center'} 
      justifyContent={'center'}>
      <VStack >
        
        <FormControl isRequired display="flex"  justifyContent={'center'}>
          {/* <FormLabel>Enter Prompt</FormLabel> */}
          <Input 
            h = '80px'
            mb = "10px"
            w = '35vw' 
            placeholder='Type your instructions...'
            bg = "white"
            rounded = 'full'
            onChange = {
                (event) => {
                    this.props.onFormTextChangeUpdate('chapter', this.props.id, 'prompt', event.target.value)
                }
            }
          />
        </FormControl >
        <HStack>
            {/* <CustomButton w = '125px' text = "Save" onClick = {() => {this.props.onSaveSnippet(this.props.id)}}/> */}
            <CustomButton w = '125px' text = "Generate" onClick = {() => {this.props.generateText(this.props.id)}}/>
          </HStack>
          <Divider bg = 'grey'/>
          <CardBody>
            <HStack>
              <EditableComponent
              id = {this.props.id} 
              editing_obj = {this.props.editing_obj}
              state = {this.props.state}
              onFormTextChangeUpdate = {this.props.onFormTextChangeUpdate}
              onSaveSnippet = {this.props.onSaveSnippet}
              />
            <HStack >
            <Spacer />
            <CloseIcon 
              cursor='pointer'
              onClick = {() => {this.props.onCancelEditSnippet(this.props.id)}}
            />
          </HStack>
        </HStack>
          </CardBody>
          
          
          
        </VStack>
      </Card>
    {/* </Box> */}
    </>
    )
  }
} 

class EditableComponent extends Component {
  handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === 'Tab') {
      // event.stopPropagation()
      // event.preventDefault()
    }
  }

  render() {
    let textEdit = this.props.editing_obj['text']
    let text = this.props.state.chapter.updates[this.props.id]['text']
    return (
      <Editable 
      // defaultValue = {textEdit}
      value = {text}
      onSubmit = {(newValue) => {
        if (newValue !== textEdit) {
          this.props.onSaveSnippet(this.props.id)}}
        }
      >
        <EditablePreview
        fontSize="xl"
        fontStyle="italic"
        letterSpacing="wide"
        lineHeight="1.8" 
        whiteSpace="pre-wrap" 
        w = '35vw' 
        >
          <Box whiteSpace="pre-wrap">{text}</Box>
        </EditablePreview>  
        <EditableTextarea 
        fontSize="xl"
        fontStyle="italic"
        letterSpacing="wide"
        lineHeight="1.8" 
        
        as="textarea" 
        w = '35vw' 
        h = '30vh' 
        bg = "white"
        onChange = {
          (event) => {
            this.props.onFormTextChangeUpdate('chapter', this.props.id, 'text', event.target.value)
          }
        }
        onKeyDown = {this.handleKeyDown}
        />
        
      </Editable>
    )
  }
}

class SavedSnippet extends Component {
  render() {
    return (
      <>
      {/* <Box w = "1000px"  > */}
      <Card variant="unstyled" w = '40vw'  p = "20px" display="flex" alignItems={'center'} justifyContent={'center'}>
      <VStack>
        
        <CardBody>
          <HStack>
          <Text 
          whiteSpace="pre-wrap"
          fontSize="xl"
          fontStyle="italic"
          letterSpacing="wide"
          lineHeight="1.8" 
          >
            {this.props.text}
          </Text>
          <HStack>
            {/* <Spacer /> */}
            <EditIcon 
              cursor='pointer'
              onClick = {() => {this.props.onEditSnippet(this.props.id)}}
            />
            <DeleteSnippetIcon 
              id = {this.props.id}
              onDeleteSnippet = {this.props.onDeleteSnippet}
            />
          </HStack>
          </HStack>
        </CardBody>
        </VStack>
      </Card>
    {/* </Box> */}
    </>
    )
  }
} 

const DeleteSnippetIcon = ({ onDeleteSnippet, id }) => {
  const [isOpen, setIsOpen] = useState(false);
  const cancelRef = useRef();

  const openDialog = () => setIsOpen(true);
  const closeDialog = () => setIsOpen(false);

  const handleDelete = (id) => {
    onDeleteSnippet(id); // Call the actual delete function passed in as a prop
    closeDialog(); // Close the dialog after deletion
  };

  return (
    <>
      {/* Trigger the confirmation dialog */}
      
      <DeleteIcon 
        cursor='pointer'
        onClick = {openDialog}
      />
    
      {/* Confirmation Dialog */}
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={closeDialog}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Snippet
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete this snippet? This action cannot be undone.
            </AlertDialogBody>

            <AlertDialogFooter>
              {/* Cancel button */}
              <Button ref={cancelRef} onClick={closeDialog}>
                Cancel
              </Button>

              {/* Confirm deletion button */}
              <Button colorScheme="red" onClick = {() => {handleDelete(id)}} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

class CustomButton extends Component {
  render() {
    return (
      <Button
        px={6}
        w = {this.props.w}
        fontSize={'lg'}
        bg={'red.400'}
        color={'white'}
        boxShadow={
            '0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)'
        }
        _hover={{
            bg: 'red.500',
        }}
        _focus={{
            bg: 'red.500',
        }}
        onClick = {this.props.onClick}
      >{this.props.text}
      </Button>
    )
  }
}





export default ChapterIndex