import React, {Component} from 'react'
import {
  Box,
  Button,
  Flex,
  Heading,
  Spacer,
  HStack,
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem
  
} from '@chakra-ui/react';
import './Navigation.css'

class Navigation extends Component {
    render () {
      return (
        <>
          <Flex
           bg = {this.props.bg} 
           as="nav" 
           alignItems="right"
          //  p="10px"
          //  position="fixed"   // Set position to fixed
          // top="0"            // Pin it to the top of the page
          // width="84%"       // Make it span the full width
          // zIndex="1000"
           >
           <Heading fontStyle = "oblique" as="h1" fontSize="2em" m="10px" mr="0" color = "red.500">Write</Heading>
           <Heading fontStyle = "oblique" as="h1" fontSize="2em" m="10px" ml="0" color = "red.400">wizard</Heading>
           <Spacer />
           <Box >
           <HStack spacing="20px">
            <UserOptions onRouteChange = {this.props.onRouteChange}/>
             </HStack>
              </Box>
            </Flex>
      </>
      )
    }
  }

  class UserOptions extends Component {
    render() {
      return (
        <Menu>
          <MenuButton variant='outline'>
            <Avatar 
              // src ="portret_2.jpg"
              cursor='pointer' 
              
              m = "10px"
            />
          </MenuButton>
          <MenuList >
            <MenuItem fontSize="lg" onClick = {() => {this.props.onRouteChange('index')}}>Books</MenuItem>
            <MenuItem fontSize="lg" onClick = {() => {this.props.onRouteChange('account')}}>My Account</MenuItem>
            <MenuItem fontSize="lg" onClick = {() => {this.props.onRouteChange('login')}}>Logout</MenuItem>
          </MenuList>
        </Menu>
      )
    }
  }

class NavButton extends Component {
  render () {
    return (
      <Button
        px={6}
        fontSize={'lg'}
        bg={'red.400'}
        color={'white'}
        boxShadow={
            '0px 1px 25px -5px rgb(66 153 225 / 48%), 0 10px 10px -5px rgb(66 153 225 / 43%)'
        }
        _hover={{
            bg: 'red.500',
        }}
        _focus={{
            bg: 'red.500',
        }}
        onClick = {this.props.onClick}
      >{this.props.text}
    </Button>
    )
  }
}

export default Navigation