import React, {Component} from 'react'
import {
    Box,
    Grid,
    GridItem,
    SimpleGrid,
    VStack
  } from '@chakra-ui/react';
import Sidebar from '../Sidebar/Sidebar';
import Navigation from '../Navigation/Navigation';


class MainPage extends Component {
    render () {
      return (
        <>
        <Grid templateColumns="repeat(48, 1fr)" bg="gray.30">
        <GridItem
          as="aside"
          colSpan="7" 
          bg="red.300"
          minHeight={{ lg: '100vh' }}
          pt={{ base: '20px'}}
        >
        <Sidebar
        state = {this.props.state}
        onRouteChange = {this.props.onRouteChange}
        getOverview = {this.props.getOverview}
        onViewChapter = {this.props.onViewChapter}
        onDeleteChapter = {this.props.onDeleteChapter}
        onGeneratePage = {this.props.onGeneratePage}
        onCreateChapter = {this.props.onCreateChapter}
        onSaveChapterTitle = {this.props.onSaveChapterTitle}
        onFormTextChangeUpdate = {this.props.onFormTextChangeUpdate}
        />
        </GridItem>
        <GridItem
        as="main"
        colSpan="41" 
        p="10px"
        bg="gray.30"
        >
        <Navigation
        state = {this.props.state}
        onRouteChange = {this.props.onRouteChange}
        onLogOut = {this.props.onLogOut}
        />
        <SimpleGrid p ="10px" minChildWidth = "250px">
        
        <Box h = "90vh" display="flex" alignItems={'center'} justifyContent={'center'}>
        <VStack>
          
          
        </VStack>
        </Box>
        
          
      
       
        </SimpleGrid>
        </GridItem>
        </Grid>
        </>
      )
    }
  }

  

export default MainPage