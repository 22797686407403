import React, {Component, useState, useRef} from "react"
import { 
  List, 
  ListItem, 
  ListIcon, 
  Button, 
  Divider, 
  Card, 
  Editable, 
  EditablePreview, 
  EditableInput, 
  HStack,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Box
} from "@chakra-ui/react"
import { EditIcon, HamburgerIcon, DeleteIcon} from '@chakra-ui/icons'

class Sidebar extends Component {
  render () {
    return (
      
      <List 
      position="fixed"
      color="white" 
      fontSize="1.2em" 
      // spacing={1}
      overflowY="auto"
      >
        <Box
      
      display="flex"             // Flex container
      flexDirection="column"     // Column direction
      alignItems="left"        // Center horizontally
      justifyContent="center"    // Center vertically
      >
        <NewChapterLine onCreateChapter = {this.props.onCreateChapter}/>
        <Divider/>
        <ChaptersList
        state = {this.props.state}
        onViewChapter = {this.props.onViewChapter}
        onDeleteChapter = {this.props.onDeleteChapter}
        onSaveChapterTitle = {this.props.onSaveChapterTitle}
        onFormTextChangeUpdate = {this.props.onFormTextChangeUpdate}
        />
        </Box>
      </List>
    
    )
  }
}

class ChaptersList extends Component {
  render () {
    let chapters = this.props.state.chapters
    return (
    <>
      {chapters['exists'] === true 
      ? 
      chapters['items'].map(chapter => (
          <ChapterLine 
          id = {chapter.id} 
          title = {chapter.title} 
          onViewChapter = {this.props.onViewChapter} 
          onDeleteChapter = {this.props.onDeleteChapter} 
          onSaveChapterTitle = {this.props.onSaveChapterTitle}
          onFormTextChangeUpdate = {this.props.onFormTextChangeUpdate}
          />
        )
      )
      :
        null
      }
    </>
    )
  }
}

function NewChapterLine ({onCreateChapter}) {
  const [isHovered, setIsHovered] = useState(false)
  // render() {
    return (
      <ListItem 
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        _hover={{ backgroundColor: 'red.500', rounded: "md" }}
        backgroundColor={isHovered ? 'red.500' : 'transparent'}
        
      >
        <HStack>
        <ListIcon ml = '8px' as={HamburgerIcon} color="white"/>
        <CustomButton title = "New Chapter" onCreateChapter = {onCreateChapter} isHovered={isHovered}/>
        </HStack>
      </ListItem>
    )
  // }
}

class ChapterLine extends Component {
  render () {
    return (
      <>
      <ListItem _hover={{ backgroundColor: 'red.500', rounded: "md" }}
      overflow="hidden"
      whiteSpace="nowrap"  
      textOverflow="ellipsis"  
      width="100%"
      >
        <HStack justify="space-between" width="100%">
          <HStack maxWidth="85%">
            <ListIcon mr = '4px' ml = '8px' cursor='pointer' as={EditIcon} color="white" onClick = {() => {this.props.onViewChapter(this.props.id)}}/>
            
            <ListButton
              id={this.props.id}
              title={this.props.title}
              onSaveChapterTitle={this.props.onSaveChapterTitle}
              onFormTextChangeUpdate={this.props.onFormTextChangeUpdate}
              isTruncated // ensures truncation within available space
            />
          
          </HStack>
          <DeleteChapterIcon
          id = {this.props.id}
          onDeleteChapter = {this.props.onDeleteChapter}
          />
        </HStack>
      </ListItem>
      </>
    )
  }
}

const DeleteChapterIcon = ({ onDeleteChapter, id }) => {
  const [isOpen, setIsOpen] = useState(false);
  const cancelRef = useRef();

  const openDialog = () => setIsOpen(true);
  const closeDialog = () => setIsOpen(false);

  const handleDelete = (id) => {
    onDeleteChapter(id); // Call the actual delete function passed in as a prop
    closeDialog(); // Close the dialog after deletion
  };

  return (
    <>
      {/* Trigger the confirmation dialog */}
      
      <DeleteIcon 
        
        mr = '8px' 
        color="black" 
        cursor='pointer'
        onClick = {openDialog}
        />
    

      {/* Confirmation Dialog */}
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={closeDialog}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Chapter
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete this chapter? This action cannot be undone.
            </AlertDialogBody>

            <AlertDialogFooter>
              {/* Cancel button */}
              <Button ref={cancelRef} onClick={closeDialog}>
                Cancel
              </Button>

              {/* Confirm deletion button */}
              <Button colorScheme="red" onClick = {() => {handleDelete(id)}} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};


class ListButton extends Component {
  render () {
    return (
      <Editable 
      defaultValue = {this.props.title} 
      onSubmit = {(newTitle) => {
        if (newTitle !== this.props.title) {
          this.props.onSaveChapterTitle(this.props.id)
          }
        }
      }
      maxWidth="calc(100% - 30px)" // Restrict width to avoid overflow
      overflow="hidden"
      whiteSpace="nowrap"
      textOverflow="ellipsis"
      mt = '2px'
      >
        <EditablePreview
        // fontSize="xl"
        // mt = '2px'
        size="sm"
        w="125px"
        overflow="hidden"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        
        />
        <EditableInput 
        // bg = "white"
        w="125px"
        onChange = {
          (event) => {
            this.props.onFormTextChangeUpdate('chapter', this.props.id, 'title', event.target.value)
          }
        }/>
        
      </Editable>
      
    )
  }
}

function CustomButton({ title, onCreateChapter, isHovered }) {
  return (
    <Button
      ml = '-16px'
      bg={isHovered ? 'red.500' : 'red.300'}
      color="white"
      _hover={{
        bg: 'red.500',
      }}
      onClick={onCreateChapter}
    >
      {title}
    </Button>
  );
}



export default Sidebar