import React, { useState, useEffect } from "react";
import { Button, Card, Text } from "@chakra-ui/react"

function Payments() {
  // Paste the stripe-pricing-table snippet in your React component
  return (
    
    <stripe-pricing-table pricing-table-id="prctbl_1QEtqvGxTEV3NhLbybxnH1FY"
    publishable-key="pk_live_51QCJc5GxTEV3NhLbXWznVBfPJVZVNO2e5Ozm4G4nyFnlxZXEYlwZzkUiiDflCuV61ihImJ0Bv1E5JiibFdQuQrvU00kVHl502r">
    </stripe-pricing-table>
    
  );
}



export default Payments