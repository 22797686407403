import React, {Component} from 'react'
// import {Form, Button, Card} from 'react-bootstrap'
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Heading,
  HStack,
  VStack
} from '@chakra-ui/react';

class SignInBoxNew extends Component {
  render() {
    return (
      <Flex
        minH={'100vh'}
        align={'center'}
        justify={'center'}
        bg="#F3F2EF"
        >
        <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
          <Stack align={'center'}>
          <HStack>
          <Heading fontStyle = "oblique" as="h0" fontSize="3em" m="10px" mr="-1" color = "red.500">Write</Heading>
          <Heading fontStyle = "oblique" as="h0" fontSize="3em" m="10px" ml="-1" color = "red.400">wizard</Heading>
          </HStack>
            <Heading align={'center'} fontStyle = "oblique" color = "gray.400"  fontSize={'4xl'}>Sign In</Heading>
          </Stack>
          <Box w = '400px'
            rounded={'lg'}
            // bg={useColorModeValue('white', 'gray.700')}
            // boxShadow={'lg'}
            p={8}
            
            >
            
            <Stack spacing={4}>
              <FormControl id="name">
                <FormLabel>Username</FormLabel>
                <Input type="name" bg = "white"
                onChange = {(event) => {this.props.onFormTextChange('loginDetails', 'username', event.target.value)}}/>
              </FormControl>
              <FormControl id="password">
                <FormLabel>Password</FormLabel>
                <Input type="password" bg = "white"
                onChange = {(event) => {this.props.onFormTextChange('loginDetails', 'password', event.target.value)}}/>
              </FormControl>
              <Stack spacing={10}>
                <Stack
                  direction={{ base: 'column', sm: 'row' }}
                  align={'start'}
                  justify={'space-between'}>
                </Stack>
                <Button
                  bg={'red.400'}
                  color={'white'}
                  _hover={{
                    bg: 'red.500',
                  }}
                  onClick = {this.props.checkSignIn}>
                  Sign In
                </Button>
                <Button
                  bg={'red.400'}
                  color={'white'}
                  _hover={{
                    bg: 'red.500',
                  }}
                  onClick = {() => {this.props.onRouteChange('register')}}>
                  To Register
                </Button>
                <Button
                  bg={'red.500'}
                  color={'white'}
                  _hover={{
                    bg: 'red.600',
                  }}
                  onClick = {() => {this.props.onRouteChange('forgot_password')}}>
                  Forgot Password?
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Flex>
    );
  }
}

class RegisterBoxNew extends Component {
  render() {
    return (
      
      
      <Flex
        minH={'100vh'}
        align={'center'}
        justify={'center'}
        // bg={useColorModeValue('gray.50', 'gray.800')}
        bg="#F3F2EF"
        >
        <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
          <Stack align={'center'}>
          <HStack>
          <Heading fontStyle = "oblique" as="h0" fontSize="3em" m="10px" mr="-1" color = "red.500">Write</Heading>
          <Heading fontStyle = "oblique" as="h0" fontSize="3em" m="10px" ml="-1" color = "red.400">wizard</Heading>
          </HStack>
            <Heading align={'center'} fontStyle = "oblique" color = "gray.400" fontSize={'4xl'}>Register</Heading>
          </Stack>
          <Box w = '400px'
            rounded={'lg'}
            // bg={useColorModeValue('white', 'gray.700')}
            // boxShadow={'lg'}
            p={8}
            // bg="#F3F2EF"
            >
            
            <Stack spacing={4}>
              <FormControl id="name">
                <FormLabel>Username</FormLabel>
                <Input type="name" bg = "white"
                onChange = {(event) => {this.props.onFormTextChange('loginDetails', 'username', event.target.value)}}/>
              </FormControl>
              <FormControl id="email">
                <FormLabel>Email</FormLabel>
                <Input type="email" bg = "white"
                onChange = {(event) => {this.props.onFormTextChange('loginDetails', 'email', event.target.value)}}/>
              </FormControl>
              <FormControl id="password">
                <FormLabel>Password</FormLabel>
                <Input type="password" bg = "white"
                onChange = {(event) => {this.props.onFormTextChange('loginDetails', 'password', event.target.value)}}/>
              </FormControl>
              <Stack spacing={10}>
                <Stack
                  direction={{ base: 'column', sm: 'row' }}
                  align={'start'}
                  justify={'space-between'}>
                </Stack>
                <Button
                  bg={'red.400'}
                  color={'white'}
                  _hover={{
                    bg: 'red.500',
                  }}
                  onClick = {() => {this.props.onRouteChange('login')}}>
                  To Login
                </Button>
                <Button
                  bg={'red.400'}
                  color={'white'}
                  _hover={{
                    bg: 'red.500',
                  }}
                  onClick = {this.props.onRegister}>
                  Register
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Flex>
    );
  }
}

class ForgotPasswordBox extends Component {
  render() {
    return (
      <Flex
        minH={'100vh'}
        align={'center'}
        justify={'center'}
        bg="#F3F2EF"
        >
        <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
          <Stack align={'center'}>
          <HStack>
          <Heading fontStyle = "oblique" as="h0" fontSize="3em" m="10px" mr="-1" color = "red.500">Write</Heading>
          <Heading fontStyle = "oblique" as="h0" fontSize="3em" m="10px" ml="-1" color = "red.400">wizard</Heading>
          </HStack>
            <Heading align={'center'} fontStyle = "oblique" color = "gray.400"  fontSize={'4xl'}>Forgot Password</Heading>
          </Stack>
          <Box w = '400px'
            rounded={'lg'}
            // bg={useColorModeValue('white', 'gray.700')}
            // boxShadow={'lg'}
            p={8}
            
            >
            
            <Stack spacing={4}>
              <FormControl id="email">
                <FormLabel>Email</FormLabel>
                <Input type="email" bg = "white"
                onChange = {(event) => {this.props.onFormTextChange('loginDetails', 'email', event.target.value)}}/>
              </FormControl>
              <Stack spacing={10}>
                <Stack
                  direction={{ base: 'column', sm: 'row' }}
                  align={'start'}
                  justify={'space-between'}>
                </Stack>
                <Button
                  bg={'red.500'}
                  color={'white'}
                  _hover={{
                    bg: 'red.600',
                  }}
                  onClick = {this.props.onSendResetEmail}>
                  Send Reset Email
                </Button>
                <Button
                  bg={'red.400'}
                  color={'white'}
                  _hover={{
                    bg: 'red.500',
                  }}
                  onClick = {() => {this.props.onRouteChange('login')}}>
                  To Login
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Flex>
    );
  }
}

class VerifyTokenBox extends Component {
  render() {
    return (
      <Flex
        minH={'100vh'}
        align={'center'}
        justify={'center'}
        bg="#F3F2EF"
        >
        <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
          <Stack align={'center'}>
          <HStack>
          <Heading fontStyle = "oblique" as="h0" fontSize="3em" m="10px" mr="-1" color = "red.500">Write</Heading>
          <Heading fontStyle = "oblique" as="h0" fontSize="3em" m="10px" ml="-1" color = "red.400">wizard</Heading>
          </HStack>
            <Heading align={'center'} fontStyle = "oblique" color = "gray.400"  fontSize={'4xl'}>Verify Token</Heading>
          </Stack>
          <Box w = '400px'
            rounded={'lg'}
            // bg={useColorModeValue('white', 'gray.700')}
            // boxShadow={'lg'}
            p={8}
            
            >
            
            <Stack spacing={4}>
              <FormControl id="token">
                <FormLabel>Token</FormLabel>
                <Input type="token" bg = "white"
                onChange = {(event) => {this.props.onFormTextChange('loginDetails', 'token', event.target.value)}}/>
              </FormControl>
              <Stack spacing={10}>
                <Stack
                  direction={{ base: 'column', sm: 'row' }}
                  align={'start'}
                  justify={'space-between'}>
                </Stack>
                <Button
                  bg={'red.500'}
                  color={'white'}
                  _hover={{
                    bg: 'red.600',
                  }}
                  onClick = {this.props.onVerifyPasswordResetToken}>
                  Verify Token
                </Button>
                <Button
                  bg={'red.400'}
                  color={'white'}
                  _hover={{
                    bg: 'red.500',
                  }}
                  onClick = {() => {this.props.onRouteChange('login')}}>
                  To Login
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Flex>
    );
  }
}

class ResetPasswordBox extends Component {
  render() {
    return (
      <Flex
        minH={'100vh'}
        align={'center'}
        justify={'center'}
        bg="#F3F2EF"
        >
        <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
          <Stack align={'center'}>
          <HStack>
          <Heading fontStyle = "oblique" as="h0" fontSize="3em" m="10px" mr="-1" color = "red.500">Write</Heading>
          <Heading fontStyle = "oblique" as="h0" fontSize="3em" m="10px" ml="-1" color = "red.400">wizard</Heading>
          </HStack>
            <Heading align={'center'} fontStyle = "oblique" color = "gray.400"  fontSize={'4xl'}>Reset Password</Heading>
          </Stack>
          <Box w = '400px'
            rounded={'lg'}
            // bg={useColorModeValue('white', 'gray.700')}
            // boxShadow={'lg'}
            p={8}
            >
            <Stack spacing={4}>
              <FormControl id="new_pw1">
                <FormLabel>New Password</FormLabel>
                <Input type="password" bg = "white"
                onChange = {(event) => {this.props.onFormTextChange('loginDetails', 'new_pw1', event.target.value)}}/>
              </FormControl>
              <FormControl id="new_pw2">
                <FormLabel>Repeat New Password</FormLabel>
                <Input type="password" bg = "white"
                onChange = {(event) => {this.props.onFormTextChange('loginDetails', 'new_pw2', event.target.value)}}/>
              </FormControl>
              <Stack spacing={10}>
                <Stack
                  direction={{ base: 'column', sm: 'row' }}
                  align={'start'}
                  justify={'space-between'}>
                </Stack>
                <Button
                  bg={'red.500'}
                  color={'white'}
                  _hover={{
                    bg: 'red.600',
                  }}
                  onClick = {this.props.onResetPassword}>
                  Reset Password
                </Button>
                <Button
                  bg={'red.400'}
                  color={'white'}
                  _hover={{
                    bg: 'red.500',
                  }}
                  onClick = {() => {this.props.onRouteChange('login')}}>
                  To Login
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Flex>
    );
  }
}



class Authentication extends Component {
  render() {
    return (
      <>
      {this.props.state.route === 'login'
        ?
      <SignInBoxNew
        onNameChange = {this.props.onNameChange}
        onPasswordChange = {this.props.onPasswordChange}
        checkSignIn = {this.props.checkSignIn}
        onRouteChange = {this.props.onRouteChange}
        onFormTextChange = {this.props.onFormTextChange}
      />
      : this.props.state.route === 'register'
      ?
        <RegisterBoxNew
          onNameChange = {this.props.onNameChange}
          onEmailChange = {this.props.onEmailChange}
          onPasswordChange = {this.props.onPasswordChange}
          onRegister = {this.props.onRegister}
          onRouteChange = {this.props.onRouteChange}
          onFormTextChange = {this.props.onFormTextChange}
        />
      : this.props.state.route === 'forgot_password'
      ?
        <ForgotPasswordBox
          onEmailChange = {this.props.onEmailChange}
          onRouteChange = {this.props.onRouteChange}
          onFormTextChange = {this.props.onFormTextChange}
          onForgotPassword = {this.props.onForgotPassword}
          onSendResetEmail = {this.props.onSendResetEmail}
        />
      : this.props.state.route === 'verifyPasswordResetToken'
      ?
        <VerifyTokenBox
          onNameChange = {this.props.onNameChange}
          onEmailChange = {this.props.onEmailChange}
          onPasswordChange = {this.props.onPasswordChange}
          onRegister = {this.props.onRegister}
          onRouteChange = {this.props.onRouteChange}
          onFormTextChange = {this.props.onFormTextChange}
          onVerifyPasswordResetToken = {this.props.onVerifyPasswordResetToken}
        />
        : this.props.state.route === 'resetPassword'
        ?
          <ResetPasswordBox
            onNameChange = {this.props.onNameChange}
            onEmailChange = {this.props.onEmailChange}
            onPasswordChange = {this.props.onPasswordChange}
            onRegister = {this.props.onRegister}
            onRouteChange = {this.props.onRouteChange}
            onFormTextChange = {this.props.onFormTextChange}
            onResetPassword = {this.props.onResetPassword}
          />
        : 
         null
      }
     </>
    )
  }
}

export default Authentication