import React, {Component} from 'react'
import {
  ChakraProvider,
  theme,
  useToast 
} from '@chakra-ui/react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


import Authentication from './subsystems/Authentication/Authentication'
import BooksIndex from './subsystems/Main/BooksIndex'
import MainPage from './subsystems/Main/Main'
import ChapterIndex from './subsystems/Main/ChapterIndex'
import Payments from './subsystems/Payments/Payments'
import Account from './subsystems/Navigation/Account'

function App() {
  
  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Routes> 
          <Route path = "/login" element = {<AppUIRoute/>}/>
          <Route path = "/register" element = {<AppUIRoute/>}/>
          <Route path = "/forgot_password" element = {<AppUIRoute/>}/>
          <Route path = "/payments" element = {<AppUIRoute/>}/>
          <Route path = "/index" element = {<AppUIRoute/>}/>
          <Route path = "/account" element = {<AppUIRoute/>}/>
          <Route path = "/book/:bookId" element = {<AppUIRoute/>}/>
          <Route path = "/chapter/:chapterId" element = {<AppUIRoute/>}/>
        </Routes>
      </Router>
    </ChakraProvider>
  );
}

function AppUIRoute() {
  const navigate = useNavigate();
  const toast = useToast();
  return <AppUI navigate = {navigate} toast = {toast}/>
}

const backendURL = 'https://writewizard-backend-2ff195d1efc3.herokuapp.com/'
// const backendURL = 'http://127.0.0.1:5001/'

const placeHolderSnippetGenerator = {
  prompt: '',
  response: {
    active: false,
    text: ''
  }
}

const initialLoginDetails = {
  username: '',
  email: '',
  password: '',
}

const initialAccountDetails = {
  username: '',
  email: '',
  password: '',
}

const initialState = {
  loginDetails: initialLoginDetails,
  accountDetails: initialAccountDetails,
  route: 'login',
  books: {
    exists: false,
    items: [],
    updates: {}
  },
  chapters: {
    book_id: 1,
    exists: false,
    items: []
  },
  chapter: {
    chapter_id: 1,
    snippets: {
      exists: false,
      items: []
    },
    updates: {}
  }
}

class AppUI extends Component {

  constructor(props) {
    super()
    this.state = initialState
  }

  onNameChange = (value) => {
      this.setState({
        username: value
      })
  }

  onEmailChange = (value) => {
    this.setState({
      email: value
    })
}

  onPasswordChange = (value) => {
    this.setState({
      password: value
    })
  }

  onRouteChange = (dest) => {
    this.setState({
      route: dest
    })
    this.props.navigate(`/${dest}`);
  }

  onLogOut = () => {
    this.setState({
      state: initialState
    })
  }

  onFormTextChange = (form, key, value) => {
    this.setState({
      [form]: {
        ...this.state[form],
        [key]: value
      }
    })
  }

  onPaginationButtonClick = (value, loc) => {
    let page = this.state[loc].page
    let add = 0
    if (value === 'Next') {
      add += 1
    } else {
      add += -1
    }
    this.setState({
      [loc]: {
        ...this.state[loc],
        page: page += add
      }
    }) 
  }

  onFormTextChangeNested4 = (level_1, level_2, key, value) => {
  //   this.setState({
  //     [level_1]: {
  //       ...this.state[level_1],
  //       [level_2]: {
  //         ...this.state[level_2],
  //         [key]: value
  //       }
  //     }
  //   })
  // }
    this.setState({
      [level_1]: {
        ...this.state[level_1],
        [level_2]: {
          ...this.state[level_1][level_2],
          [key]: value
        }
      }
    })
  }

  onFormTextChangeUpdateAcctInfo = (level_1, type, value) => {
    this.setState({
      [level_1]: {
        ...this.state[level_1],
        updates: {
          ...this.state[level_1]['updates'],
            [type]: value
        }
      }
    })
  }

  onFormTextChangeUpdate = (level_1, snippet_id, type, value) => {
      this.setState({
        [level_1]: {
          ...this.state[level_1],
          updates: {
            ...this.state[level_1]['updates'],
            [snippet_id]: {
              ...this.state[level_1]['updates'][snippet_id],
              [type]: value
            }
          }
        }
      })
    }

    onFormTextChangeBookTitle = (level_1, snippet_id, type, value) => {
      this.setState({
        [level_1]: {
          ...this.state[level_1],
          updates: {
            ...this.state[level_1]['updates'],
            [snippet_id]: {
              [type]: value
            }
          }
        }
      })
    }

  onGeneratePage = () => {
    this.setState({
      route: 'generatePage',
      snippetGenerator: placeHolderSnippetGenerator
    })
  }

  onClickChangePassword = (type) => {
    this.setState({
      accountDetails: {
        ...this.state.accountDetails,
        editingPassword: type
      }
    })
  }

  onRegister = async () => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        user: this.state.loginDetails.username,
        email: this.state.loginDetails.email,
        password: this.state.loginDetails.password
      })
    }
    try {

      let response = await (await fetch(backendURL + 'register', requestOptions)).json()
      // let response = await (await fetch(backendURL + 'create-checkout-session', requestOptions)).json()
      let {success, message, result} = response
      if (success == true) {
        let {account, books} = result
        this.props.toast({
          title: 'Success',
          description: message,
          position: 'top',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
        this.setState({
          ...this.state,
          accountDetails: account,
          books: books,
          route: 'index'
        })
        this.props.navigate('/index');
      } else {
        this.props.toast({
          title: 'Error',
          description: message,
          position: 'top',
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      } 
    } catch (error) {
        this.props.toast({
          title: 'Error',
          description: error.message,
          position: 'top',
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      }
  }

  onHandleCheckout = async () => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    }
    let response = await (await fetch(backendURL + 'create_checkout_session', requestOptions)).json()
    // const stripe = await stripePromise;
    // const { error } = await stripe.redirectToCheckout({ sessionId: session.id });
    // if (error) {
    //   console.error('Stripe checkout failed', error);
    // }
  }

  checkSignIn = async () => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        user: this.state.loginDetails.username,
        password: this.state.loginDetails.password
      })
    }
    try {
      let response = await (await fetch(backendURL + 'check_signin', requestOptions)).json()
      let {success, message, result} = response
      if (success == true) {
        let {account, books} = result
        this.setState({
          ...this.state,
          accountDetails: account,
          books: books,
          route: 'index'
        })
        this.props.navigate('/index');
      } else {
        this.props.toast({
          title: 'Error',
          description: message,
          position: 'top',
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      }
    } catch (error) {
        this.props.toast({
          title: 'Error',
          description: error.message,
          position: 'top',
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
    }
    
  }

  generateText = async (snippet_id) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: this.state.loginDetails.username,
        chapter_id: this.state.chapter.chapter_id,
        snippet_id: snippet_id,
        prompt: this.state.chapter.updates[snippet_id]['prompt']
      })
    }
    try {
      let response = await (await fetch(backendURL + 'generate_text', requestOptions)).json()
      let {success, message, result} = response
      let successMessage = success ? 'Success' : 'Error'
      let status = success ? 'success' : 'error'
      if (success == true) {
        let {chapter_id, snippets, updates} = result
        this.setState({
          ...this.state,
          chapter: result
        })
        this.props.toast({
          title: successMessage,
          description: message,
          position: 'top',
          status: status,
          duration: 3000,
          isClosable: true,
        })
      } else {
        this.props.toast({
          title: successMessage,
          description: message,
          position: 'top',
          status: status,
          duration: 3000,
          isClosable: true,
        })
      }
    } catch (error) {
        this.props.toast({
          title: 'Error',
          description: error.message,
          position: 'top',
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
    }
    
  }

  onSaveSnippet = async (snippet_id) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: this.state.loginDetails.username,
        chapter_id: this.state.chapter.chapter_id,
        snippet_id: snippet_id,
        text: this.state.chapter.updates[snippet_id]['text']
      })
    }
    try {
      let response = await (await fetch(backendURL + 'save_snippet', requestOptions)).json()
      let {success, message, result} = response
      this.setState({
        ...this.state,
        chapter: result
      })
    } catch (error) {
      this.props.toast({
        title: 'Error',
        description: error.message,
        position: 'top',
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
  }

  onViewChapter = async (chapter_id) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: this.state.loginDetails.username,
        chapter_id: chapter_id
      })
    }
    try {
      let response = await (await fetch(backendURL + 'view_chapter', requestOptions)).json()
      let {success, message, result} = response
      this.setState({
        ...this.state,
        route: 'chapterIndex',
        chapter: result
      })
      this.props.navigate(`/chapter/${chapter_id}`);
    } catch (error) {
      this.props.toast({
        title: 'Error',
        description: error.message,
        position: 'top',
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
  }

  onEditSnippet = async (snippet_id) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: this.state.loginDetails.username,
        chapter_id: this.state.chapter.chapter_id,
        snippet_id: snippet_id,
      })
    }
    try {
      let response = await (await fetch(backendURL + 'edit_snippet', requestOptions)).json()
      let {success, message, result} = response
      this.setState({
        ...this.state,
        chapter: result
      })
    } catch (error) {
      this.props.toast({
        title: 'Error',
        description: error.message,
        position: 'top',
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
  }

  onCancelEditSnippet = async (snippet_id) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: this.state.loginDetails.username,
        chapter_id: this.state.chapter.chapter_id,
        snippet_id: snippet_id,
      })
    }
    try {
      let response = await (await fetch(backendURL + 'cancel_edit_snippet', requestOptions)).json()
      let {success, message, result} = response
      this.setState({
        ...this.state,
        chapter: result
      })
    } catch (error) {
      this.props.toast({
        title: 'Error',
        description: error.message,
        position: 'top',
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
  }

  onDeleteSnippet = async (snippet_id) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: this.state.loginDetails.username,
        chapter_id: this.state.chapter.chapter_id,
        snippet_id: snippet_id
      })
    }
    try {
      let response = await (await fetch(backendURL + 'delete_snippet', requestOptions)).json()
      let {success, message, result} = response
      this.setState({
        ...this.state,
        chapter: result,
      })
    } catch (error) {
      this.props.toast({
        title: 'Error',
        description: error.message,
        position: 'top',
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
  }

  onDeleteChapter = async (chapter_id) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: this.state.loginDetails.username,
        chapter_id: chapter_id,
        book_id: this.state.chapters.book_id
      })
    }
    try {
      let response = await (await fetch(backendURL + 'delete_chapter', requestOptions)).json()
      let {success, message, result} = response
      this.setState({
        ...this.state,
        chapters: result,
      })
    } catch (error) {
      this.props.toast({
        title: 'Error',
        description: error.message,
        position: 'top',
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
  }

  onCreateSnippet = async (chapter_id) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: this.state.loginDetails.username,
        chapter_id: chapter_id,
        book_id: this.state.chapters.book_id
      })
    }
    try {
      let response = await (await fetch(backendURL + 'create_snippet', requestOptions)).json()
      let { success, message, result } = response
      let status = success ? 'success' : 'warning'
      let title = success ? 'Success' : 'Error'
      this.setState({
        ...this.state,
        chapter: result
      })
      this.props.toast({
        title: title,
        description: message,
        position: 'top',
        status: status,
        duration: 3000,
        isClosable: true,
      })  
    } catch (error) {
      this.props.toast({
        title: 'Error',
        description: error.message,
        position: 'top',
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
  }

  onCreateChapter = async () => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: this.state.loginDetails.username,
        book_id: this.state.chapters.book_id
      })
    }
    try {
      let response = await (await fetch(backendURL + 'create_chapter', requestOptions)).json()
      let { success, message, result } = response
      let status = success ? 'success' : 'warning'
      let title = success ? 'Success' : 'Error'
      this.setState({
        ...this.state,
        chapters: result,
      })
      this.props.toast({
        title: title,
        description: message,
        position: 'top',
        status: status,
        duration: 3000,
        isClosable: true,
      })  
    } catch (error) {
      this.props.toast({
        title: 'Error',
        description: error.message,
        position: 'top',
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
  }

  onCreateBook = async () => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: this.state.loginDetails.username
      })
    }
    try {
      let response = await (await fetch(backendURL + 'create_book', requestOptions)).json()
      let { success, message, result } = response
      this.setState({
        ...this.state,
        books: result,
      })
      let status = success ? 'success' : 'warning'
      let title = success ? 'Success' : 'Error'
      this.props.toast({
        title: title,
        description: message,
        position: 'top',
        status: status,
        duration: 3000,
        isClosable: true,
      })
    } catch (error) {
      this.props.toast({
        title: 'Error',
        description: error.message,
        position: 'top',
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
  }

  onViewBook = async (book_id) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: this.state.loginDetails.username,
        book_id: book_id
      })
    }
    try {
      let response = await (await fetch(backendURL + 'view_book', requestOptions)).json()
      let { success, message, result } = response
      this.setState({
        ...this.state,
        route: 'mainPage',
        chapters: result,
      })
      this.props.navigate(`/book/${book_id}`);
    } catch (error) {
      this.props.toast({
        title: 'Error',
        description: error.message,
        position: 'top',
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
  }

  onDeleteBook = async (book_id) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: this.state.loginDetails.username,
        book_id: book_id
      })
    }
    try {
      let response = await (await fetch(backendURL + 'delete_book', requestOptions)).json()
      let { success, message, result } = response
      this.setState({
        ...this.state,
        books: result,
      })
    } catch (error) {
      this.props.toast({
        title: 'Error',
        description: error.message,
        position: 'top',
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
  }

  onSaveBookTitle = async (book_id) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: this.state.loginDetails.username,
        book_id: book_id,
        title: this.state.books.updates[book_id]['title']
      })
    }
    try {
      let response = await (await fetch(backendURL + 'save_book_title', requestOptions)).json()
      let { success, message, result } = response
      this.setState({
        ...this.state,
        books: result,
      })
    } catch (error) {
      this.props.toast({
        title: 'Error',
        description: error.message,
        position: 'top',
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
  }

  onSaveChapterTitle = async (chapter_id) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: this.state.loginDetails.username,
        book_id: this.state.chapters.book_id,
        chapter_id: chapter_id,
        title: this.state.chapter.updates[chapter_id]['title']
      })
    }
    try {
      let response = await (await fetch(backendURL + 'save_chapter_title', requestOptions)).json()
      let { success, message, result } = response
      this.setState({
        ...this.state,
        chapters: result,
      })
    } catch (error) {
      this.props.toast({
        title: 'Error',
        description: error.message,
        position: 'top',
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
  }

  onChangeAccountInfo = async (type) => {
    let user_id = this.state.accountDetails.account_info.user_id
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        user_id: user_id,
        type: type,
        new_value: this.state.accountDetails.updates[type]
      })
    }
    try {
      let response = await (await fetch(backendURL + 'change_account_info', requestOptions)).json()
      let { success, message, result } = response
      let status = success ? 'success' : 'warning'
      let title = success ? 'Success' : 'Error'
      this.setState({
        ...this.state,
        accountDetails: result,
      })
      this.props.toast({
        title: title,
        description: message,
        position: 'top',
        status: status,
        duration: 3000,
        isClosable: true,
      })
    } catch (error) {
      this.props.toast({
        title: 'Error',
        description: error.message,
        position: 'top',
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
  }

  onSaveNewPassword = async (type) => {
    let user_id = this.state.accountDetails.account_info.user_id
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        user_id: user_id,
        current_pw: this.state.accountDetails.updates['current_password'],
        new_pw_1: this.state.accountDetails.updates['new_password_1'],
        new_pw_2: this.state.accountDetails.updates['new_password_2'],
      })
    }
    
    try {
      let response = await (await fetch(backendURL + 'save_new_password', requestOptions)).json()
      let { success, message, result } = response
      let status = success ? 'success' : 'warning'
      let title = success ? 'Success' : 'Error'
      
      this.props.toast({
        title: title,
        description: message,
        position: 'top',
        status: status,
        duration: 3000,
        isClosable: true,
      })
      if (success) {
        this.setState({
          accountDetails: {
            ...this.state.accountDetails,
            editingPassword: false
          }
        })
      }
    } catch (error) {
      this.props.toast({
        title: 'Error',
        description: error.message,
        position: 'top',
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
  }

  onChangeSubscription = async (type) => {
    let accountDetails = this.state.accountDetails
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        user_id: accountDetails.account_info.user_id,
        subscr_info: accountDetails.subscr_info,
        type: type
      })
    }
    
    try {
      let response = await (await fetch(backendURL + 'cancel_subscription', requestOptions)).json()
      let { success, message, result } = response
      let status = success ? 'success' : 'warning'
      let title = success ? 'Success' : 'Error'
      
      this.props.toast({
        title: title,
        description: message,
        position: 'top',
        status: status,
        duration: 10000,
        isClosable: true,
      })
      if (success) {
        this.setState({
          ...this.state,
          accountDetails: result,
        })
      }
    } catch (error) {
      this.props.toast({
        title: 'Error',
        description: error.message,
        position: 'top',
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
  }

  onSendResetEmail = async (type) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: this.state.loginDetails.email
      })
    }
    
    try {
      let response = await (await fetch(backendURL + 'on_send_reset_email', requestOptions)).json()
      let { success, message, result } = response
      let status = success ? 'success' : 'warning'
      let title = success ? 'Success' : 'Error'
      
      this.props.toast({
        title: title,
        description: message,
        position: 'top',
        status: status,
        duration: 10000,
        isClosable: true,
      })
      if (success) {
        this.setState({
          ...this.state,
          route: 'verifyPasswordResetToken',
        })
      }
    } catch (error) {
      this.props.toast({
        title: 'Error',
        description: error.message,
        position: 'top',
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
  }

  onVerifyPasswordResetToken = async () => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        token: this.state.loginDetails.token
      })
    }
    
    try {
      let response = await (await fetch(backendURL + 'verify_password_reset_token', requestOptions)).json()
      let { success, message } = response
      let status = success ? 'success' : 'warning'
      let title = success ? 'Success' : 'Error'
      
      this.props.toast({
        title: title,
        description: message,
        position: 'top',
        status: status,
        duration: 10000,
        isClosable: true,
      })
      if (success) {
        this.setState({
          ...this.state,
          route: 'resetPassword',
        })
      }
    } catch (error) {
      this.props.toast({
        title: 'Error',
        description: error.message,
        position: 'top',
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
  }

  onResetPassword = async () => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: this.state.loginDetails.email,
        pw1: this.state.loginDetails.new_pw1,
        pw2: this.state.loginDetails.new_pw2,
      })
    }
    try {
      let response = await (await fetch(backendURL + 'reset_password', requestOptions)).json()
      let { success, message, result } = response
      let status = success ? 'success' : 'warning'
      let title = success ? 'Success' : 'Error'
      
      this.props.toast({
        title: title,
        description: message,
        position: 'top',
        status: status,
        duration: 10000,
        isClosable: true,
      })
      if (success) {
        this.setState({
          ...this.state,
          route: 'login',
        })
      }
    } catch (error) {
      this.props.toast({
        title: 'Error',
        description: error.message,
        position: 'top',
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
  }

  render() {
    
    return (
      <div className="app">
      {this.state.route === 'mainPage'
        ?
        <div className = "mainPage">
          <MainPage
          state = {this.state}
          onRouteChange = {this.onRouteChange}
          onCreateChapter = {this.onCreateChapter}
          onDeleteChapter = {this.onDeleteChapter}
          onViewChapter = {this.onViewChapter}
          onLogOut = {this.onLogOut}
          onSaveChapterTitle = {this.onSaveChapterTitle}
          onFormTextChangeUpdate = {this.onFormTextChangeUpdate}
          />
        </div>
        : this.state.route === 'index'
        ?
        <div className = "index">
          <BooksIndex
          state = {this.state}
          onRouteChange = {this.onRouteChange}
          onFormTextChangeBookTitle = {this.onFormTextChangeBookTitle}
          onCreateBook = {this.onCreateBook}
          onDeleteBook = {this.onDeleteBook}
          onViewBook = {this.onViewBook}
          onLogOut = {this.onLogOut}
          onSaveBookTitle = {this.onSaveBookTitle}
          />
        </div>
        : this.state.route === 'payments'
        ?
        <div className = "payments">
          <Payments
          state = {this.state}
          onHandleCheckout = {this.onHandleCheckout}
          />
        </div>
        : this.state.route === 'account'
        ?
        <div className = "account">
          <Account
          state = {this.state}
          onRouteChange = {this.onRouteChange}
          onChangeAccountInfo = {this.onChangeAccountInfo}
          onHandleCheckout = {this.onHandleCheckout}
          onFormTextChangeUpdateAcctInfo = {this.onFormTextChangeUpdateAcctInfo}
          onClickChangePassword = {this.onClickChangePassword}
          onSaveNewPassword = {this.onSaveNewPassword}
          onFormTextChange = {this.onFormTextChange}
          onChangeSubscription = {this.onChangeSubscription}
          />
        </div>
        : this.state.route === 'chapterIndex'
        ?
        <div className = "chapterIndex">
          <ChapterIndex
          state = {this.state}
          onRouteChange = {this.onRouteChange}
          onFormTextChange = {this.onFormTextChange}
          onFormTextChangeUpdate = {this.onFormTextChangeUpdate}
          generateText = {this.generateText}
          onSaveSnippet = {this.onSaveSnippet}
          onViewChapter = {this.onViewChapter}
          onEditSnippet = {this.onEditSnippet}
          onDeleteChapter = {this.onDeleteChapter}
          onDeleteSnippet = {this.onDeleteSnippet}
          onGeneratePage = {this.onGeneratePage}
          onCreateChapter = {this.onCreateChapter}
          onCreateSnippet = {this.onCreateSnippet}
          onCancelEditSnippet = {this.onCancelEditSnippet}
          onLogOut = {this.onLogOut}
          onSaveChapterTitle = {this.onSaveChapterTitle}
          />
        </div>
        : 
          <div class = "auth">
            <Authentication
            onFormTextChange = {this.onFormTextChange}
            onRegister = {this.onRegister}
            checkSignIn = {this.checkSignIn}
            onRouteChange = {this.onRouteChange}
            state = {this.state}
            onSendResetEmail = {this.onSendResetEmail}
            onVerifyPasswordResetToken = {this.onVerifyPasswordResetToken}
            onResetPassword = {this.onResetPassword}
            />
          </div>
        }
        </div>
    )
  }
}

export default App;
